import SectionSlider2 from "../slider/section-slider2";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";
import HomeAutomazione from "../MASP/homeautomazione";
import Clienti_Component from "../clienti/clienti_component";
import ServicesHome from "../servizi/servizi_home/servicesHome";
import Intro_Prodotti from "./intro_prodotti";
import SupervisorHomeCard from "./supervisor_homecard";
import MesHomeCard from "./mes_homecard";
import SchedeHomeCard from "./schede_homecard";
import PercheSceglierciNew from "../perche_sceglierci/perche_sceglierci_new";
import JobViewerCard from "../../elements/jobViewerCard";
import IntroMasp from "../MASP/intromasp";
function Home2Page() {


  const isMobile = window.innerWidth >= 768;
  useEffect(() => {
    loadScript("js/custom.js")
  })

  return (
    <>

      {false && <JobViewerCard title="Robot collaborativo" folderName="Robot collaborativo"></JobViewerCard>}
      {false && <JobViewerCard title="avvitatura" folderName="avvitatura"></JobViewerCard>}
      {false && <JobViewerCard title="avvitatura a chiave" folderName="avvitatura a chiave"></JobViewerCard>}
      {false && <IntroMasp />}
      <SectionSlider2 />
      <Intro_Prodotti></Intro_Prodotti>
      <HomeAutomazione titolo={false}></HomeAutomazione>
      <SupervisorHomeCard></SupervisorHomeCard>
      <MesHomeCard></MesHomeCard>
      <SchedeHomeCard></SchedeHomeCard>
      <ServicesHome></ServicesHome>
      <Clienti_Component partner={true}></Clienti_Component>
      <PercheSceglierciNew></PercheSceglierciNew>
      {/*<SectionWhyChooseUs2 />*/}
    </>
  );
}

export default Home2Page;